<template>
  <div id="PaymentSlipDetails">
    <v-container fluid v-if="getCollegeUserPaymentSlipDetails.hasOwnProperty('all_std_fee')">
      <v-card color="white">
        <v-layout row>
          <v-card flat>
            <v-card-text class="title">Fee Summary Details</v-card-text>
          </v-card>
          <v-spacer />
        </v-layout>
      </v-card>
      <table class="v-datatable v-table theme--light">
          <thead>
            <tr>
              <th
                role="columnheader"
                scope="col"
                width="10"
                aria-label="SL.: Not sorted."
                aria-sort="none"
                class="column text-xs-left"
              >
                SL.
              </th>
              <th
                role="columnheader"
                scope="col"
                aria-label="SL.: Not sorted."
                aria-sort="none"
                class="column text-xs-left"
              >
              Candidate Type
              </th>
              <th
                role="columnheader"
                scope="col"
                aria-label="Number of Candidate(s): Not sorted."
                aria-sort="none"
                class="column text-xs-left"
              >
              No. of Candidate 
              </th>
              <th
                role="columnheader"
                scope="col"
                aria-label="Total: Not sorted."
                aria-sort="none"
                class="column text-xs-left"
              >
              Total
              </th>
            </tr>
          </thead>
          <tbody>
            <slot v-if="getCollegeUserPaymentSlipDetails.hasOwnProperty('all_std_fee')">
              <tr v-for="(item, index) in getCollegeUserPaymentSlipDetails.all_std_fee" :key="index">         
                <td>{{ index + 1 }}</td>               
                <td class="text-xs-left"><span v-if="item.student_type == 1">{{ item.candidate_type }}</span><span v-else>PRIVATE</span></td>
                <td class="text-xs-left">{{ item.total_student }}</td>
                <td class="text-xs-left">{{ item.fee }}</td>
              </tr>
            </slot>
            <tr v-if="getCollegeUserPaymentSlipDetails.hasOwnProperty('total_fee')">                     
                <td class="text-xs-left" colspan="2">Total</td>
                <td class="text-xs-left">{{ getCollegeUserPaymentSlipDetails.total_fee.total_student }}</td>
                <td class="text-xs-left">{{ getCollegeUserPaymentSlipDetails.total_fee.fee }}</td>
              </tr>
          </tbody>
          
        </table>
    </v-container>
  </div>
</template>
<script>

export default {
  props: ['referenceId'],
  components: {},
  computed: {
    getCollegeUserPaymentSlipDetails(){
      return this.$store.getters.getCollegeUserPaymentSlipDetails;
    }
  },
  data() {
    return {
    };
  },
  created() {
    this.getDataFromApi();
  },
  methods: {
    getDataFromApi() {
      let current_privilege = JSON.parse(localStorage.getItem("current-privilege"));
      let params = {
        current_privilege: current_privilege,
        trxid: this.$route.params.referenceId
      }
      this.$store.dispatch("getCollegeUserPaymentSlipDetails", {params: params})
    }
  },
  mounted() {
  }
};
</script>
<style scoped>
table.v-table tbody td, table.v-table tbody th {
  height: 0px;
}
</style>